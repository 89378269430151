import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { getGatsbyImageData } from "gatsby-source-sanity"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import moment from "moment"
import "moment/locale/de"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./galerie.scss"
import BlockContent from "../components/block-content"

moment.locale("de")

const GaleriePage = ({ data: { sanityGalleryPageSettings: data } }) => {
  console.log(data)
  return (
    <Layout page="galerie">
      <SEO title={data.title} />
      <div className="display-header">
        <h2>{data.heading}</h2>
      </div>
      <div>
        <BlockContent blocks={data._rawBody} />
      </div>
      <div className="instagram-posts">
        <Row>
          {data.images.map((image, idx) => {
            const imageData = getGatsbyImageData(
              image.asset._id,
              { maxWidth: 400 },
              {
                projectId: "rkn8y74e",
                dataset: "production",
              }
            )
            return (
              <Col key={idx} xs={6} lg={3} className="post">
                <a href={image.asset.url}>
                  <GatsbyImage image={imageData} />
                </a>
              </Col>
            )
          })}
        </Row>
      </div>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <a
            className="insta-more"
            href="https://www.instagram.com/goschsgasthof"
          >
            <i className="fab fa-instagram"></i>
            <span>Mehr auf Instagram entdecken</span>
          </a>
        </Col>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GalerieQuery {
    sanityGalleryPageSettings(_id: { eq: "galleryPageSettings" }) {
      title
      heading
      _rawBody
      images {
        asset {
          _id
          url
        }
      }
    }
  }
`

export default GaleriePage
